import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Building2, Settings, RefreshCw, HelpCircle } from 'lucide-react';

const Layout = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation Bar */}
      <nav className="bg-white shadow-sm p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div 
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => navigate('/')}
          >
            <Building2 className="h-6 w-6 text-blue-600" />
            <span className="text-xl font-semibold text-gray-800">Mall Reviews</span>
          </div>
          <div className="flex space-x-4">
            <button 
              className="p-2 hover:bg-gray-100 rounded-full"
              onClick={() => navigate('/config')}
            >
              <Settings className="h-5 w-5 text-gray-600" />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <RefreshCw className="h-5 w-5 text-gray-600" />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <HelpCircle className="h-5 w-5 text-gray-600" />
            </button>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <Outlet />
    </div>
  );
};

export default Layout;