import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Calendar, Clock, Link, Play, Database } from "lucide-react";

const ScrapingConfig = () => {
  const navigate = useNavigate();
  const [frequency, setFrequency] = useState("daily");
  const [status, setStatus] = useState({
    lastRun: "2024-03-15 14:30",
    itemsExtracted: 1250,
    status: "success",
  });

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-lg shadow-sm">
          <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">
              Configuración
            </h1>
            {/* Status Information */}
            <div className="bg-gray-50 rounded-lg p-4 mb-6">
              <h3 className="text-lg font-medium text-gray-800 mb-3">Status</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="flex items-center">
                  <Clock className="h-5 w-5 text-gray-400 mr-2" />
                  <div>
                    <p className="text-sm text-gray-600">Última ejecución</p>
                    <p className="font-medium">{status.lastRun}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Database className="h-5 w-5 text-gray-400 mr-2" />
                  <div>
                    <p className="text-sm text-gray-600">Items extraídos</p>
                    <p className="font-medium">{status.itemsExtracted}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div
                    className={`h-2 w-2 rounded-full ${
                      status.status === "success"
                        ? "bg-green-500"
                        : "bg-red-500"
                    } mr-2`}
                  />
                  <p className="font-medium capitalize">{status.status}</p>
                </div>
              </div>
            </div>
            {/* URL Configuration
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                URL Base para Scraping
              </label>
              <div className="flex items-center">
                <Link className="h-5 w-5 text-gray-400 absolute ml-3" />
                <input
                  type="text"
                  className="pl-10 w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="https://example.com/reviews"
                />
              </div>
            </div> */}

            {/* Date Range */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Fecha Inicio
                </label>
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400 absolute ml-3" />
                  <input
                    type="date"
                    className="pl-10 w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Fecha Fin
                </label>
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400 absolute ml-3" />
                  <input
                    type="date"
                    className="pl-10 w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>

            {/* Frequency Configuration */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Frecuencia de Scraping
              </label>
              <select
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="hourly">Cada hora</option>
                <option value="daily">Diario</option>
                <option value="weekly">Semanal</option>
                <option value="monthly">Mensual</option>
              </select>
            </div>

            {/* Authentication
            <div className="mb-6">
              <h3 className="text-lg font-medium text-gray-800 mb-4">
                Autenticación
              </h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Usuario
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div> */}

            {/* Action Buttons */}
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                onClick={handleCancel}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center"
                onClick={() => {
                  /* Start scraping */
                }}
              >
                <Play className="h-4 w-4 mr-2" />
                Forzar Ejecución
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrapingConfig;
