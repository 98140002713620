import React from "react";
import {
  Star,
  Menu,
  Calendar,
  ShoppingCart,
  MessageSquare,
  User,
  Clock,
  ChevronLeft,
} from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { mockCategoryData, mockReviewsList } from "../fixtures/mall-detail.tsx";

// Mock data for prototype
const mockStoreDetail = {
  id: 1,
  title: "Café Central",
  category: "Restaurante",
  complete_address: "Local 45, Planta 1, Centro Comercial Gran Vía",
  price_range: "€€",
  descriptions:
    "Cafetería y restaurante con ambiente acogedor, especializada en desayunos y comidas ligeras.",
  review_rating: 4.5,
  review_count: 320,
  menu: "https://menu.cafe-central.com",
  reservations: "https://reservas.cafe-central.com",
  order_online: "https://pedidos.cafe-central.com",
};

// const mockReviewsList = [
//   {
//     id: 1,
//     rating: 5,
//     comments:
//       "Excelente servicio y comida deliciosa. El ambiente es muy agradable.",
//     user: "María G.",
//     date_of_review: "2024-03-15",
//     is_response: false,
//   },
//   {
//     id: 2,
//     rating: 4,
//     comments: "Buena experiencia en general. Los precios son un poco elevados.",
//     user: "Juan R.",
//     date_of_review: "2024-03-10",
//     is_response: false,
//   },
// ];

const mockRatingTrend = [
  { month: "Ene", rating: 4.2 },
  { month: "Feb", rating: 4.3 },
  { month: "Mar", rating: 4.5 },
  { month: "Abr", rating: 4.4 },
  { month: "May", rating: 4.3 },
  { month: "Jun", rating: 3.6 },
  { month: "Jul", rating: 3.5 },
  { month: "Ago", rating: 3.4 },
  { month: "Sep", rating: 3.5 },
  { month: "Oct", rating: 4.7 },
  { month: "Nov", rating: 4.6 },
  { month: "Dic", rating: 4.8 },
];

const StoreDetail = ({ store, onBack }) => {
  return (
    <div className="container mx-auto py-8 px-4">
      {/* Header */}
      <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">{store.title}</h1>

            <p className="text-gray-600 mt-1">
              {
                mockCategoryData.find(
                  (category) => category.type === store.type
                )?.category
              }
            </p>
            <p className="text-gray-600 mt-1">{store.complete_address}</p>
            <p className="text-gray-600 mt-1">
              Rango de precios: {store.price_range ? store.price_range : "€€"}
            </p>
          </div>
          <div className="flex space-x-4">
            <button className="p-2 hover:bg-gray-100 rounded-lg">
              <Menu className="h-5 w-5 text-gray-600" />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-lg">
              <Calendar className="h-5 w-5 text-gray-600" />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-lg">
              <ShoppingCart className="h-5 w-5 text-gray-600" />
            </button>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex items-center">
            <Star className="h-5 w-5 text-yellow-400 fill-current" />
            <span className="ml-1 font-medium text-gray-700">
              {store.review_rating}
            </span>
            <span className="mx-1 text-gray-400">•</span>
            <span className="text-gray-600">{store.review_count} reviews</span>
          </div>
          <p className="mt-4 text-gray-700">{store.descriptions}</p>
        </div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">
            Evolución del Rating
          </h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={mockRatingTrend}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis domain={[0, 5]} />
                <Tooltip />
                <Line type="monotone" dataKey="rating" stroke="#3B82F6" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">
            Palabras más frecuentes
          </h2>
          <div className="h-64 flex items-center justify-center text-gray-500">
            Nube de palabras aquí
          </div>
        </div>
      </div>

      {/* Reviews */}
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Reseñas</h2>
          <div className="space-y-6">
            {mockReviewsList.map((review) => (
              <div key={review.id} className="border-b pb-6 last:border-b-0">
                <div className="flex items-start justify-between">
                  <div className="flex items-center">
                    <div className="bg-gray-100 rounded-full p-2">
                      <User className="h-5 w-5 text-gray-600" />
                    </div>
                    <div className="ml-3">
                      <p className="font-medium text-gray-800">{review.Name}</p>
                      <div className="flex items-center mt-1">
                        <Star className="h-4 w-4 text-yellow-400 fill-current" />
                        <span className="ml-1 text-sm text-gray-600">
                          {review.Rating}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center text-sm text-gray-500">
                    <Clock className="h-4 w-4 mr-1" />
                    {review.When}
                  </div>
                </div>
                <p className="mt-3 text-gray-700">{review.Description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreDetail;
