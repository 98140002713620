import React, { useState } from "react";
import {
  Phone,
  Globe,
  Clock,
  Star,
  BarChart3,
  ChevronRight,
  ArrowLeft,
} from "lucide-react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
} from "recharts";
import StoreDetail from "./store-detail.tsx";
import {
  mockCategoryData,
  categoryColors,
  mockStoresList,
} from "../fixtures/mall-detail.tsx";
// Mock data for prototype
const mockMallDetail = {
  id: 1,
  title: "Centro Comercial Gran Vía",
  complete_address: "Calle Principal 123, Ciudad, CP 28001",
  open_hours: "L-D: 10:00 - 22:00",
  phone: "+34 912 345 678",
  website: "www.granvia-mall.com",
  timezone: "Europe/Madrid",
  review_rating: 4.3,
  review_count: 1250,
};

// const mockStoresList = [
//   {
//     id: 1,
//     title: "Café Central",
//     category: "Restaurante",
//     review_rating: 4.5,
//     review_count: 320,
//   },
//   {
//     id: 2,
//     title: "Fashion Store",
//     category: "Ropa",
//     review_rating: 4.2,
//     review_count: 180,
//   },
// ];

// const mockCategoryData = [
//   { category: "Restaurantes", rating: 4.5 },
//   { category: "Ropa", rating: 4.2 },
//   { category: "Tecnología", rating: 4.0 },
//   { category: "Servicios", rating: 3.8 },
// ];

const MallDetail = ({ mall, onBack }) => {
  const [currentView, setCurrentView] = useState("main");
  const [selectedStore, setSelectedStore] = useState(null);

  const handleViewDetails = (store) => {
    setSelectedStore(store);
    setCurrentView("store-detail");
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <button
        className="mb-4 flex items-center text-sm text-blue-600 hover:text-blue-700"
        onClick={onBack}
      >
        <ArrowLeft className="mr-1 h-4 w-4" />
        <span>Go Back</span>
      </button>
      {currentView === "main" && (
        <>
          {/* Header */}
          <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
            <div className="flex flex-col md:flex-row items-start">
              {/* Mall info */}
              <div className="flex-1">
                <h1 className="text-2xl font-bold text-gray-800">
                  {mall.title}
                </h1>
                <p className="text-gray-600 mt-2">{mall.complete_address}</p>
                <div className="flex items-center mt-2">
                  <Star className="h-5 w-5 text-yellow-400 fill-current" />
                  <span className="ml-1 font-medium text-gray-700">
                    {mall.review_rating}
                  </span>
                  <span className="mx-1 text-gray-400">•</span>
                  <span className="text-gray-600">
                    {mall.review_count} reviews
                  </span>
                </div>
              </div>
              {/* Image */}
              <div className="ml-6 mt-4 md:mt-0">
                {mall.image_url && (
                  <img
                    src={mall.image_url}
                    alt={mall.title}
                    className="w-32 h-32 object-cover rounded"
                  />
                )}
              </div>
            </div>

            {/* Contact Info */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
              <div className="flex items-center">
                <Clock className="h-5 w-5 text-gray-400" />
                <span className="ml-2 text-gray-600">{mall.open_hours}</span>
              </div>
              <div className="flex items-center">
                <Phone className="h-5 w-5 text-gray-400" />
                <span className="ml-2 text-gray-600">{mall.phone}</span>
              </div>
              <div className="flex items-center">
                <Globe className="h-5 w-5 text-gray-400" />
                <a
                  href={`https://${mall.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-blue-600 hover:underline"
                >
                  {mall.website}
                </a>
              </div>
            </div>
          </div>

          {/* Charts */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">
                Rating por Categoría
              </h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={mockCategoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="category" />
                    <YAxis domain={[0, 5]} />
                    <Tooltip />
                    <Bar dataKey="rating">
                      {mockCategoryData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={categoryColors[entry.type]}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            {/* Pie char */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">
                Tipo de Comercios
              </h2>
              <ResponsiveContainer width="100%" height={256}>
                <PieChart>
                  <Pie
                    data={mockCategoryData.map((category) => ({
                      category: category.category,
                      value: mockStoresList.filter(
                        (store) => store.type === category.type
                      ).length,
                    }))}
                    dataKey="value"
                    nameKey="category"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label
                  >
                    {mockCategoryData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={categoryColors[entry.type]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Stores List */}
          <div className="bg-white rounded-lg shadow-sm">
            <div className="p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Locales
              </h2>
              <div className="space-y-4">
                {mockStoresList.map((store) => (
                  <div
                    key={store.id}
                    className="border rounded-lg p-4 hover:border-blue-200 transition-colors"
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-lg font-medium text-gray-800">
                          {store.title}
                        </h3>
                        <p className="text-sm text-gray-600">
                          {
                            mockCategoryData.find(
                              (item) => item.type === store.type
                            )?.category
                          }
                        </p>
                      </div>
                      <div className="flex items-center">
                        <Star className="h-4 w-4 text-yellow-400 fill-current" />
                        <span className="ml-1 text-sm font-medium text-gray-700">
                          {store.review_rating}
                        </span>
                        <span className="mx-1 text-gray-400">•</span>
                        <span className="text-sm text-gray-600">
                          {store.review_count} reviews
                        </span>
                      </div>
                    </div>
                    <button
                      className="mt-3 flex items-center text-sm text-blue-600 hover:text-blue-700"
                      onClick={() => handleViewDetails(store)}
                    >
                      <span>Ver Detalles</span>
                      <ChevronRight className="ml-1 h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      {currentView === "store-detail" && selectedStore && (
        <StoreDetail
          store={selectedStore}
          onBack={() => setCurrentView("main")}
        />
      )}
    </div>
  );
};

export default MallDetail;
