const mockMallsList = [
  {
    id: 1,
    title: "Saler",
    location: "Valencia",
    complete_address:
      "Avenida del Profesor López Piñero, 16. 46013 Valencia. España.",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 961 234 567",
    website: "www.ccsaler.com",
    timezone: "Europe/Madrid",
    review_rating: 4.5,
    review_count: 980,
    image_url:
      "https://www.merlinproperties.com/wp-content/uploads/2022/04/saler_0_0.jpg",
  },
  {
    id: 2,
    title: "Porto Pi",
    location: "Palma de Mallorca",
    complete_address:
      "Avinguda de Gabriel Roca, 54. 07015 Palma, Illes Balears. España.",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 971 234 567",
    website: "www.portopi.com",
    timezone: "Europe/Madrid",
    review_rating: 4.4,
    review_count: 1120,
    image_url:
      "https://www.merlinproperties.com/wp-content/uploads/2023/03/porto_pi_0_1.jpg",
  },
  {
    id: 3,
    title: "Arturo Soria Plaza",
    location: "Madrid",
    complete_address: "Calle de Arturo Soria, 126. 28043 Madrid. España.",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 913 234 567",
    website: "www.arturosoriaplaza.com",
    timezone: "Europe/Madrid",
    review_rating: 4.3,
    review_count: 890,
    image_url:
      "https://www.merlinproperties.com/wp-content/uploads/2023/03/arturo_soria_0_1.jpg",
  },
  {
    id: 4,
    title: "Marineda City",
    complete_address: "Coruña",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 981 234 567",
    website: "www.marinedacity.com",
    timezone: "Europe/Madrid",
    review_rating: 4.6,
    review_count: 1340,
    image_url: "/centro_comercial_placeholder.png",
    //   "https://www.merlinproperties.com/wp-content/uploads/2020/01/marinedacity.jpg",
  },
  {
    id: 5,
    title: "X-Madrid",
    complete_address: "Alcorcón",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 916 234 567",
    website: "www.xmadrid.com",
    timezone: "Europe/Madrid",
    review_rating: 4.7,
    review_count: 1500,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/xmadrid.jpg",
  },
  {
    id: 6,
    title: "Arenas de Barcelona",
    complete_address: "Barcelona",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 932 234 567",
    website: "www.arenasdebarcelona.com",
    timezone: "Europe/Madrid",
    review_rating: 4.5,
    review_count: 1100,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/arenasdebarcelona.jpg",
  },
  {
    id: 7,
    title: "Tresaguas",
    complete_address: "Alcorcón",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 916 234 567",
    website: "www.tresaguas.com",
    timezone: "Europe/Madrid",
    review_rating: 4.4,
    review_count: 950,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/tresaguas.jpg",
  },
  {
    id: 8,
    title: "Almada Forum",
    complete_address: "Lisboa",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+351 212 234 567",
    website: "www.almadaforum.com",
    timezone: "Europe/Lisbon",
    review_rating: 4.6,
    review_count: 1200,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/almadaforum.jpg",
  },
  {
    id: 9,
    title: "Larios Centro",
    complete_address: "Málaga",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 952 234 567",
    website: "www.larioscentro.com",
    timezone: "Europe/Madrid",
    review_rating: 4.5,
    review_count: 1050,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/larioscentro.jpg",
  },
  {
    id: 10,
    title: "La Vital",
    complete_address: "Gandía",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 962 234 567",
    website: "www.lavital.com",
    timezone: "Europe/Madrid",
    review_rating: 4.4,
    review_count: 900,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/lavital.jpg",
  },
  {
    id: 11,
    title: "Artea",
    complete_address: "Bilbao",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 944 234 567",
    website: "www.artea.com",
    timezone: "Europe/Madrid",
    review_rating: 4.5,
    review_count: 980,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/artea.jpg",
  },
  {
    id: 12,
    title: "Centro Oeste",
    complete_address: "Majadahonda",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 916 234 567",
    website: "www.centrooeste.com",
    timezone: "Europe/Madrid",
    review_rating: 4.4,
    review_count: 920,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/centrooeste.jpg",
  },
  {
    id: 13,
    title: "CALLAO",
    complete_address: "Madrid",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 915 234 567",
    website: "www.callaomadrid.com",
    timezone: "Europe/Madrid",
    review_rating: 4.6,
    review_count: 1100,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/callaomadrid.jpg",
  },
  {
    id: 14,
    title: "CUBOS MADRID",
    complete_address: "Madrid",
    open_hours: "L-D: 10:00 - 22:00",
    phone: "+34 914 234 567",
    website: "www.cubosmadrid.com",
    timezone: "Europe/Madrid",
    review_rating: 4.5,
    review_count: 1000,
    image_url: "/centro_comercial_placeholder.png",
    //"https://www.merlinproperties.com/wp-content/uploads/2020/01/cubosmadrid.jpg",
  },
];

const mockCategoryData = [
  {
    category: "Restaurantes",
    rating: 4.4,
    type: "SUPERMARKETS_AND_FOOD",
    count: 3,
  },
  { category: "Ropa", rating: 4.0, type: "FASHION", count: 5 },
  { category: "Tecnología", rating: 4.1, type: "ACCESSORIES", count: 4 },
  { category: "Servicios", rating: 4.0, type: "SERVICES", count: 2 },
  {
    category: "Entretenimiento",
    rating: 4.5,
    type: "BOOKS_MUSIC_AND_MULTIMEDIA",
    count: 4,
  },
  { category: "Deportes", rating: 4.3, type: "SPORT", count: 2 },
  { category: "Salud", rating: 4.6, type: "BEAUTY", count: 3 },
  { category: "Educación", rating: 4.2, type: "SERVICES", count: 3 },
  { category: "Hogar", rating: 4.4, type: "SPECIALIZED", count: 2 },
  { category: "Belleza", rating: 4.2, type: "BEAUTY", count: 1 },
];
const categoryColors = {
  FASHION: "#FF5733",
  SUPERMARKETS_AND_FOOD: "#33FF57",
  ACCESSORIES: "#3357FF",
  SERVICES: "#FF33A1",
  BOOKS_MUSIC_AND_MULTIMEDIA: "#A133FF",
  SPECIALIZED: "#33FFF5",
  BEAUTY: "#FF8C33",
  SPORT: "#FFD700", // Changed color to avoid repetition
};

const mockStoresList = [
  {
    id: 1,
    title: "Tienda de alimentacion",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 3.0,
    review_count: 45,
  },
  {
    id: 2,
    title: "Supermercados Dia",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 3.7,
    review_count: 50,
  },
  {
    id: 3,
    title: "Supermercados Dia",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 3.6,
    review_count: 60,
  },
  {
    id: 4,
    title: "Supermercados Dia",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 3.7,
    review_count: 40,
  },
  {
    id: 5,
    title: "La Tienda de España",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.5,
    review_count: 70,
  },
  {
    id: 6,
    title: "Tienda Alimentación",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.2,
    review_count: 30,
  },
  {
    id: 7,
    title: "Ginos",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.1,
    review_count: 25,
  },
  {
    id: 8,
    title: "La Txulapona - Restaurante Arturo Soria Plaza",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.6,
    review_count: 80,
  },
  {
    id: 9,
    title: "Supermercados Dia",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.8,
    review_count: 100,
  },
  {
    id: 10,
    title: "Restaurante Bienmesabe Asura",
    type: "SPORSUPERMARKETS_AND_FOODT",
    review_rating: 4.4,
    review_count: 90,
  },
  {
    id: 11,
    title: "Bar restaurante La Boulé",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 3.9,
    review_count: 20,
  },
  {
    id: 12,
    title: "Lateral Arturo Soria",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.0,
    review_count: 18,
  },
  {
    id: 13,
    title: "Restaurante Argentino The Knife",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 3.5,
    review_count: 15,
  },
  {
    id: 14,
    title: "Casa Carmen Arturo Soria",
    type: "BEAUTY",
    review_rating: 4.3,
    review_count: 22,
  },
  {
    id: 15,
    title: "La Madonna Sushita",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.7,
    review_count: 60,
  },
  {
    id: 16,
    title: "Antonella Madrid",
    type: "SPECIALIZED",
    review_rating: 4.5,
    review_count: 110,
  },
  {
    id: 17,
    title: "RIBS ARTURO SORIA",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.0,
    review_count: 50,
  },
  {
    id: 18,
    title: "Creperie La Fontaine",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 3.8,
    review_count: 45,
  },
  {
    id: 19,
    title: "El Rinconcito de la Cabaña",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.2,
    review_count: 35,
  },
  {
    id: 20,
    title: "Emyfa Arturo Soria",
    type: "SERVICES",
    review_rating: 4.1,
    review_count: 30,
  },
  {
    id: 21,
    title: "Restaurante Tragabuches",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.0,
    review_count: 85,
  },
  {
    id: 22,
    title: "Pícaro Restobar",
    type: "BEAUTY",
    review_rating: 4.7,
    review_count: 75,
  },
  {
    id: 23,
    title: "Sibuya Urban Sushi Bar",
    type: "BOOKS_MUSIC_AND_MULTIMEDIA",
    review_rating: 4.6,
    review_count: 50,
  },
  {
    id: 24,
    title: "Rodilla",
    type: "SPORT",
    review_rating: 4.3,
    review_count: 70,
  },
  {
    id: 25,
    title: "Sushi Koi",
    type: "SUPERMARKETS_AND_FOOD",
    review_rating: 4.8,
    review_count: 65,
  },
  {
    id: 26,
    title: "Restaurante La Bolera",
    type: "FASHION",
    review_rating: 4.4,
    review_count: 38,
  },
  {
    id: 27,
    title: "Callao24 by Jhosef Arias",
    type: "ACCESSORIES",
    review_rating: 4.5,
    review_count: 25,
  },
  {
    id: 28,
    title: "Hamburguesería Goiko",
    type: "SPECIALIZED",
    review_rating: 4.2,
    review_count: 120,
  },
];

const mockReviewsList = [
  {
    id: 1,
    Name: "César Calvo",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjXGZ6Z7T9wu8VySr2j4DvBT1h8CH4XsEy4K3t0WF0MTmsWi_T8=s120-c-rp-mo-ba2-br100",
    Rating: 2,
    Description:
      "No tiene muchas cosas para comprar', de hecho no tiene ni flashes",
    Images: null,
    When: "",
  },
  {
    id: 2,
    Name: "Raphaël Robin",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjXHY_t88CNMOBDmSBEzFkhbTwnyfdslH-jgYRkfOkSXEr41xmwd=s120-c-rp-mo-br100",
    Rating: 5,
    Description: "Familia simpática",
    Images: null,
    When: "",
  },
  {
    id: 3,
    Name: "Carlos",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjXIfsh2QaFeNrQ7oEjEp8CQjmGyijB484TMazVgmnpyD5uPrD2bTA=s120-c-rp-mo-ba5-br100",
    Rating: 2,
    Description: "Típico chino.",
    Images: null,
    When: "",
  },
  {
    id: 4,
    Name: "Lara Santos Torres",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjUY6_73ohlEOWtFM9-swsWByUsXzj8tRsgbiLUIfNEpolI-OHw0=s120-c-rp-mo-ba6-br100",
    Rating: 4,
    Description:
      "Restaurante italiano en la zona de Arturo Soria. Muy tranquilo, reservamos aunque no habría hecho falta porque había bastantes mesas vacías. La comida está rica, recomendable la pizza en forma de estrella con los bordes rellenos de pesto. La pasta que viene dentro del queso para mi gusto es mejorable, le falta algo de cremosidad. El pan que te ponen está muy bueno, es como pan de masa de pizza. La atención es buena. El baño de mujeres es el mismo que el de minusválidos. Me equivoqué y por error entré al de hombres, al no ver bien en el de mujeres la foto que tienen de una mujer, debajo de la placa de minusválidos que a mi parecer llama más la atención. Deberían señalizarlo mejor",
    Images: [
      "www.google.com/local/imagery/report/?cb_client=maps_sv.tactile\u0026image_key=!1e10!2sAF1QipPj_n6497UQquT6j2WGNUPE48H2Uc-J_RVlXJ5c\u0026fid=0x0:0x144c575d0a125d61",
    ],
    When: "2024-10-20",
  },
  {
    id: 5,
    Name: "Jon Ugarte Urizar",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjWe31DLSSIoUTBJRjDbDiYcg0l2D7JFLo8Ubwvh1aImHtIGM9vKJg=s120-c-rp-mo-ba6-br100",
    Rating: 4,
    Description:
      "Como en muchos sitios, un montón de mesas vacías y preguntan por si tienes reserva, y luego intentan meterte dentro en lugar de la terraza donde queríamos.\nLa pizza, tipo napolitana, masa gruesa, buena sin más .\nLa pasta, sosa(siendo de 4 quesos 🧀 😮😮) y tuvimos que pedir pimienta negra, lo que la mejoró enormemente.\nEl tiramisú, servido en una cafetera italiana, solo por eso merece la pena la visita, EXQUISITO 😋😍😍",
    Images: [
      "www.google.com/local/imagery/report/?cb_client=maps_sv.tactile\u0026image_key=!1e10!2sAF1QipNkHJkEx3-YQFsOmdTCDgngEeCBl4kqFqE9gr20\u0026fid=0x0:0x144c575d0a125d61",
    ],
    When: "2024-6-22",
  },
  {
    id: 6,
    Name: "Felicidad Lista Matey",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjVC-tpClqFW_MdmYxMAFts3IrDpi0zs64UM_ajnmRk7NZAmDskydA=s120-c-rp-mo-ba3-br100",
    Rating: 2,
    Description:
      "Conocemos este sitio desde hace muchísimos años, hubo una época en la que acudíamos asiduamente sobre todo por sus carbonara… después, reformaron el local y para nuestra sorpresa, algo más debió de cambiar ya que la calidad bajó muchísimo.\nHemos estado varios años sin venir por este motivo, pero decidimos darle otra oportunidad ya que seguía abierto… y nos ha vuelto a decepcionar.\nLos espagueti al vino sabían completamente a alcohol fuerte, sin evaporar, cero integración con el queso pecorino y el guuanciale. Los rigatoni con rabo de toro muy tontorrones, la verdad.\nPor otro lado, nos dieron el aperitivo de mortadella y para nuestra sorpresa, nos pusieron una lonchita para cada uno…que digo yo que igual se arruinan si ponen un pelín más.\n\nEl servicio muy amable, si bien tardaron 25 minutos en servirnos algo de comer…\n\nProbablemente, esta sea la última oportunidad que le damos.",
    Images: [
      "www.google.com/local/imagery/report/?cb_client=maps_sv.tactile\u0026image_key=!1e10!2sAF1QipMkJLMzUse9vNvffVI0XnCtAqMCHfSZLMNiQ4vS\u0026fid=0x0:0x144c575d0a125d61",
    ],
    When: "2024-11-17",
  },
  {
    id: 7,
    Name: "Nerea Fernández",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjWem1Y0Z0uBWiaeHl-gcqUvTA-TTTZO_Pt1RMZwbrhop6RvkN26Tw=s120-c-rp-mo-ba4-br100",
    Rating: 4,
    Description:
      "Tienen una carta muy amplia y los platos están bien emplatados y decorados. La cantidad, aunque en un principio pueda parecer pequeña, es perfecta. La comida está muy buena, pero en nuestro caso, nos la sirvieron fría (lo que no se entiende, puesto que no había mucha gente y fueron bastante rápidos en traerla).\n\nEl local es muy acogedor y tranquilo, y del servicio destacaría su profesionalidad, en tanto en cuanto nos ofrecieron algo de beber nada más sentarnos en la mesa, o también pasaron ofreciendo un servicio de pan muy completo. Quizá eché en falta algo más de amabilidad.",
    Images: [
      "www.google.com/local/imagery/report/?cb_client=maps_sv.tactile\u0026image_key=!1e10!2sAF1QipPmI5QBJF-U_P9ea2Fm5irH3NsO4Ou5ZRQ9ay4K\u0026fid=0x0:0x144c575d0a125d61",
    ],
    When: "2024-3-6",
  },
  {
    id: 8,
    Name: "Silvia Valerieva Mitova",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjU_TiKElqAVzpdzOMuHXat4i8pcVlnh3NP_C8CYAznDjR5UEhB3=s120-c-rp-mo-ba4-br100",
    Rating: 1,
    Description:
      "Nefasta y desagradable experiencia!\nPara no volver jamás.\nReservamos mesa para dos en la terraza y nada más que tomamos asiento y comenzaron los disgustos.\nNo permiten que se fume en la terraza y NO llevan cartel donde pone que en la terraza está NO ESTÁ PERMITIDO FUMAR.\nEn segundo lugar:En la terraza había muchas avispas y los camareros NO NOS HAN AVISADO…SIN EMBARGO AVISARON UN GRUPO DE 6 personas que hay avispas en la terraza porqué “hay una piscina en el patio de la urbanización colindante”\nNos trajeron carpacio que estaba exageradamente húmedo y una “tabla de embutidos italianos” con 2 lonchas de mortadela y un recipiente de parmesano más seco y duro que una roca…Claro,las avispas vinieron enseguida en los platos!Muy incomoda situación.\nLa foto del cartel de fumar está colocado correctamente y tal y cómo lo exige la ley,pero LA TERRAZA NO HABÍA NINGÚN CARTEL.\nNos fuimos sin  cenar por el mal ambiente y lamentable trato y cocina.\nNo volveremos!",
    Images: [
      "www.google.com/local/imagery/report/?cb_client=maps_sv.tactile\u0026image_key=!1e10!2sAF1QipMgUQ2bCzCodPNKbz8wwmZUiFTRpDaKGHuyKzBa\u0026fid=0x0:0x144c575d0a125d61",
    ],
    When: "2024-7-21",
  },
  {
    id: 9,
    Name: "PMS",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjWawZVkWU4-dAh7qEZE_bsJkLLIGABaaZddVtqevhc3tF9soeP7Gw=s120-c-rp-mo-ba4-br100",
    Rating: 5,
    Description:
      "Los platos que pedimos estaban muy buenos, algo caros. Mientras esperas la comida te traen como cortesía una tabla de queso y pan que luego cobran (cosa que habría que avisar)  El local es muy bonito y es agradable estar en él. Nos atendieron muy bien y se molestan en que estés cómodo. Lo recomendamos.",
    Images: [
      "www.google.com/local/imagery/report/?cb_client=maps_sv.tactile\u0026image_key=!1e10!2sAF1QipPowA8TO0gxNWV2iOPVVFpo1o68OZSCwiIP1SyX\u0026fid=0x0:0x144c575d0a125d61",
    ],
    When: "2024-3-31",
  },
  {
    id: 10,
    Name: "Mateo Gómez Ríos",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjVkLY6yjNBPFNBAo4_KBwkf1dvfdrfuf2RNTvceA3AjP6-S4Fe8=s120-c-rp-mo-br100",
    Rating: 5,
    Description:
      "Increíble la comida, la atención y el lugar, riquísimo todo. Nos atendieron MUY bien! Volveremos!",
    Images: [
      "www.google.com/local/imagery/report/?cb_client=maps_sv.tactile\u0026image_key=!1e10!2sAF1QipNMcp6uzukSSUWXPBwffrRhTmn8-EkaZDpibi_L\u0026fid=0x0:0x144c575d0a125d61",
    ],
    When: "2024-12-1",
  },
  {
    id: 11,
    Name: "Sandra Castro",
    ProfilePicture:
      "https://lh3.googleusercontent.com/a-/ALV-UjV3ZdRXCOBaW_5mQwd-zA9t9mARmV8ChmGWKc7jmqAGBlUqwK935w=s120-c-rp-mo-ba2-br100",
    Rating: 5,
    Description:
      "Escogimos este restaurante para cenar con mis amigas; y la atención de la camarera muy atenta y amable, también el chico que nos ha traído nuestros platos. Me pedí una pasta con rabo de toro, estaba súper rica. El rabo de toro en combinación con la pasta de lo mejor! Lo recomiendo mucho.",
    Images: [
      "www.google.com/local/imagery/report/?cb_client=maps_sv.tactile\u0026image_key=!1e10!2sAF1QipNPE2U4F3dBHn70t7h4IiOX4UIKrMdELFWYMQqp\u0026fid=0x0:0x144c575d0a125d61",
    ],
    When: "2024-2-2",
  },
];

// mockCategoryData.forEach((category, index) => {
//     category.type = categories[index % categories.length];
// });
export {
  mockMallsList,
  mockCategoryData,
  categoryColors,
  mockStoresList,
  mockReviewsList,
};
