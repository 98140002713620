import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout.tsx";
import MallList from "./views/mall-app.tsx";
import MallDetail from "./views/mall-detail.tsx";
import StoreDetail from "./views/store-detail.tsx";
import ScrapingConfig from "./views/scraping-config.tsx";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* Ruta principal - Lista de centros comerciales */}
          <Route index element={<MallList />} />

          {/* Detalles de centro comercial */}
          <Route
            path="mall/:id"
            element={
              <MallDetail
                onBack={() => {
                  /* handle back action */
                }}
              />
            }
          />

          {/* Detalles de tienda */}
          <Route path="store/:id" element={<StoreDetail />} />

          {/* Configuración de scraping */}
          <Route path="config" element={<ScrapingConfig />} />

          {/* Ruta 404 - No encontrado */}
          <Route
            path="*"
            element={
              <div className="flex flex-col items-center justify-center min-h-screen">
                <h1 className="text-2xl font-bold text-gray-800">
                  404 - Página no encontrada
                </h1>
                <p className="mt-2 text-gray-600">
                  La página que buscas no existe.
                </p>
              </div>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
