import React, { useState } from "react";
import { Star, ChevronRight } from "lucide-react";
import MallDetail from "./mall-detail.tsx";
import { mockMallsList } from "../fixtures/mall-detail.tsx";

// Mock data for prototype
// const mockMallsList = [
//   {
//     id: 1,
//     title: "Centro Comercial Gran Vía",
//     address: "Calle Principal 123",
//     thumbnail: "/api/placeholder/100/100",
//     review_rating: 4.3,
//     review_count: 1250,
//   },
//   {
//     id: 2,
//     title: "Plaza Mayor Shopping",
//     address: "Avenida Central 456",
//     thumbnail: "/api/placeholder/100/100",
//     review_rating: 4.5,
//     review_count: 980,
//   },
// ];

const App = () => {
  const [currentView, setCurrentView] = useState("main");
  const [selectedMall, setSelectedMall] = useState(null);

  const handleViewDetails = (mall) => {
    setSelectedMall(mall);
    setCurrentView("mall-detail");
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation Bar
      <nav className="bg-white shadow-sm p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Building2 className="h-6 w-6 text-blue-600" />
            <span className="text-xl font-semibold text-gray-800">
              Mall Reviews
            </span>
          </div>
          <div className="flex space-x-4">
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <Settings className="h-5 w-5 text-gray-600" />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <RefreshCw className="h-5 w-5 text-gray-600" />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <HelpCircle className="h-5 w-5 text-gray-600" />
            </button>
          </div>
        </div>
      </nav> */}

      {currentView === "main" && (
        <>
          {/* Main Content */}
          <main className="container mx-auto py-8 px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {mockMallsList.map((mall) => (
                <div
                  key={mall.id}
                  className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className="p-4">
                    <div className="flex">
                      <img
                        src={mall.image_url}
                        alt={mall.title}
                        className="w-24 h-24 rounded-lg object-cover"
                      />
                      <div className="ml-4 flex-1">
                        <h3 className="text-lg font-semibold text-gray-800">
                          {mall.title}
                        </h3>
                        <p className="text-gray-600 text-sm mt-1">
                          {mall.location
                            ? mall.location
                            : mall.complete_address}
                        </p>
                        <div className="flex items-center mt-2">
                          <Star className="h-4 w-4 text-yellow-400 fill-current" />
                          <span className="ml-1 text-sm font-medium text-gray-700">
                            {mall.review_rating}
                          </span>
                          <span className="mx-1 text-gray-400">•</span>
                          <span className="text-sm text-gray-600">
                            {mall.review_count} reviews
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => handleViewDetails(mall)}
                      className="mt-4 w-full flex items-center justify-center px-4 py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
                    >
                      <span>Ver Detalles</span>
                      <ChevronRight className="ml-2 h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </main>
        </>
      )}

      {currentView === "mall-detail" && selectedMall && (
        <MallDetail mall={selectedMall} onBack={() => setCurrentView("main")} />
      )}
    </div>
  );
};

export default App;
